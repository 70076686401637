@import '@carbon/charts/styles/styles.scss';

@import 'carbon-components/scss/components/loading/loading';
@import './mixins.scss';
@import './overrides.scss';

.landing-page__illo {
  max-width: 100%;
}

.landing-page__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-07 * 1;
  @include landing-page-background;
}

.landing-page__heading {
  @include carbon--type-style('productive-heading-05');
}

.landing-page__r2 {
  margin-top: rem(-40px);
}

.landing-page__tab-content {
  padding-top: $layout-04;
  padding-bottom: $layout-05;
}

.landing-page__subheading {
  @include carbon--type-style('productive-heading-03');
  @include carbon--font-weight('semibold');
}

.landing-page__p {
  @include carbon--type-style('productive-heading-03');
  margin-top: $spacing-06;
  margin-bottom: $spacing-08;

  @include carbon--breakpoint-between((320px + 1), md) {
    max-width: 75%;
  }
}

.landing-page__r3 {
  padding-top: $spacing-09;
  padding-bottom: $spacing-09;
  @include landing-page-background;
}

.landing-page__label {
  @include carbon--type-style('heading-01');
}

.agency-stackedbar {
  margin-top: 30px;
  overflow-x: auto;
  white-space: nowrap;
}

.graph-loader {
  text-align: center;
  margin: auto;
}
.mage-tile {
  background-color: white;
}

.certificate-tile {
  padding: 0.5rem;
  background-color: white;
  min-height: 7rem !important;
  padding: 0.9rem !important;
}

.certificate-tile-inner {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.certificate-tile-name {
  font-size: 0.7rem;
  min-height: 3.5rem;
  max-width: 4.5rem;
  a {
    color: #ffffff;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}

.certificate-tile-value {
  font-weight: bold;
  color: #ffffff;
}

.certification-tile-wrapper {
  padding-left: 0;
  padding-right: 0;
}
.cert-tile-container {
  margin-bottom: 0.1rem;
  padding: 0.1rem;
}
.certification-tab {
  margin-top: 2.5rem;
  // overflow: hidden;
}

.contact-atish {
  font-size: 0.8em;
}
