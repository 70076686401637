@import 'carbon-components/scss/components/tile/tile';
@import 'carbon-components/scss/components/tag/tag';

.contributors-page__r1 {
  padding-top: $spacing-05;
  padding-bottom: $spacing-05;
}

.contributor-center {
  text-align: center;
  padding: 1rem;
}
