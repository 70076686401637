@import 'carbon-components/scss/components/tile/tile';
@import 'carbon-components/scss/components/tag/tag';

.data-accuracy-page__r1 {
  padding-top: $spacing-05;
  padding-bottom: $spacing-05;
}

.data-accuracy-center {
  text-align: center;
  padding: 1rem;
}

.data-accuracy-item {
  padding-top: 1em;
}
