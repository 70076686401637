@import '@carbon/themes/scss/themes';

// Pass in the theme to override
$carbon--theme: map-merge(
  $carbon--theme--white,
  (
    // Specify your overrides
    interactive-01: #ed6c25,
    interactive-02: #171717,
    interactive-03: #ed6c25,
    interactive-04: #ed6c25,
    ui-background: #ffffff,
    ui-01: #f3f3f3,
    ui-02: #ffffff,
    ui-03: #dcdcdc,
    ui-04: #8c8c8c,
    ui-05: #171717,
    text-01: #171717,
    text-02: #565656,
    text-03: #8c8c8c,
    text-04: #ffffff,
    icon-01: #171717,
    icon-02: #565656,
    icon-03: #ffffff,
    link-01: #ed6c25,
    field-01: #f3f3f3,
    field-02: #ffffff,
    inverse-01: #ffffff,
    inverse-02: #3d3d3d,
    support-01: #da1e28,
    support-02: #24a148,
    support-03: #fdd13a,
    support-04: #ed6c25,
    inverse-support-01: #fb4b53,
    inverse-support-02: #3dbb61,
    inverse-support-03: #fdd13a,
    inverse-support-04: #408bfc,
    overlay-01: rgba(23, 23, 23, 0.5),
    focus: #ed6c25,
    hover-primary: #eb7e44,
    active-primary: #ed6c25,
    hover-primary-text: #ed6c25,
    hover-secondary: #4c4c4c,
    active-secondary: #6f6f6f,
    hover-tertiary: #eb7e44,
    active-tertiary: #ed6c25,
    hover-ui: #e5e5e5,
    active-ui: #bebebe,
    selected-ui: #dcdcdc,
    hover-selected-ui: #cacaca,
    hover-danger: #ba1b23,
    active-danger: #750e13,
    hover-row: #e5e5e5,
    visited-link: #eb7e44,
    disabled-01: #f3f3f3,
    disabled-02: #bebebe,
    disabled-03: #8c8c8c,
    skeleton-01: #e5e5e5,
    skeleton-02: #bebebe,
    brand-01: #ed6c25,
    brand-02: #171717,
    brand-03: #ed6c25,
    active-01: #bebebe,
    hover-field: #e5e5e5
  )
);

@include carbon--theme();
